<template>
  <NavMenu defaultActive="/case/list">
    <div class="patient page">
      <div class="main">
        <div class="header">
          <div>
            <img src="../../assets/img/icon_3.png" alt="">
            <span>{{ $t('case.pre') }}</span>
          </div>
          <el-button class="edit" type="primary" @click="edit">{{ $t('common.edit') }}</el-button>
        </div>
        <div class="flag">
          <span></span>
          <span>{{ $t('report.m1') }}</span>
        </div>
        <div class="bseInfo">
          <div>
            <span>{{ $t('report.m2') }}:</span>
            <span>{{ cases ? cases.information : '' }}</span>
          </div>
          <div>
            <span>{{ $t('patient.name') }}:</span>
            <span>{{ patient ? patient.patient_name : '' }}</span>
          </div>
          <div>
            <span>{{ $t('patient.patient') }}:</span>
            <span>{{ cases ? cases.case_number : '' }}</span>
          </div>
          <div>
            <span>{{ $t('patient.date') }}:</span>
            <span>{{ cases ? cases.add_time_text : '' }}</span>
          </div>
          <div>
            <span>{{ $t('report.slide') }}:</span>
            <span>{{ cases ? cases.slide_text : '' }}</span>
          </div>
        </div>
        <div class="mParams">
          <ul class="gray myul">
            <li>{{ $t('report.t1') }}</li>
            <li>{{ $t('report.t2') }}</li>
            <li>{{ $t('report.t3') }}</li>
            <li>{{ $t('report.t4') }}</li>
          </ul>
          <ul class="myul">
            <li>{{ $t('report.angle') }}</li>
            <li>{{ baseinfo ? baseinfo[0].flip_text + baseinfo[0].flip_angle + '°' : '' }}</li>
            <li>{{ baseinfo ? baseinfo[1].flip_text + baseinfo[1].flip_angle + '°' : '' }}</li>
            <li>{{ baseinfo ? baseinfo[2].flip_text + baseinfo[2].flip_angle + '°' : '' }}</li>
          </ul>
          <ul class="myul">
            <li>{{ $t('report.move') }}</li>
            <li>{{ baseinfo ? baseinfo[0].slide_text + baseinfo[0].slide_dis + 'mm' : '' }}</li>
            <li>{{ baseinfo ? baseinfo[1].slide_text + baseinfo[1].slide_dis + 'mm' : '' }}</li>
            <li>{{ baseinfo ? baseinfo[2].slide_text + baseinfo[2].slide_dis + 'mm' : '' }}</li>
          </ul>
        </div>
        <div class="kjParams">
          <ul class="gray myul">
            <li>{{ $t('report.t5') }}</li>
            <li>{{ $t('report.t6') }}</li>
            <li>{{ $t('report.t7') }}</li>
          </ul>
          <ul class="myul">
            <li>{{ $t('report.nearRing') }}</li>
            <li>{{ install ? install[0].solid_name : '' }}</li>
            <li>{{ install ? install[0].solid_long + 'mm' : '' }}</li>
          </ul>
          <ul class="myul">
            <li>{{ $t('report.farRing') }}</li>
            <li>{{ install ? install[1].solid_name : '' }}</li>
            <li>{{ install ? install[1].solid_long + 'mm' : '' }}</li>
          </ul>
        </div>

        <div class="install">
          <ul class="gray myul">
            <li>{{ $t('report.t15') }}</li>
            <li>{{ $t('report.t2') }}</li>
            <li>{{ $t('report.t3') }}</li>
            <li>{{ $t('report.t4') }}</li>
          </ul>
          <ul class="myul">
            <li>{{ $t('report.t8') }}</li>
            <li>{{ install ? install[0].in_out_slide_text : '' }} {{ install ? install[0].middle_dis : '' }}mm</li>
            <li>{{ install ? install[0].before_after_slide_text : '' }} {{ install ? install[0].slide_dis : '' }}mm</li>
            <li>{{ install ? install[0].offset_dis : '' }}mm</li>
          </ul>
          <ul class="myul">
            <li>{{ $t('report.t9') }}</li>
            <li>{{ install ? install[1].in_out_slide_text : '' }} {{ install ? install[1].middle_dis : '' }}mm</li>
            <li>{{ install ? install[1].before_after_slide_text : '' }} {{ install ? install[1].slide_dis : '' }}mm</li>
            <li>{{ install ? install[1].offset_dis : '' }}mm</li>
          </ul>
        </div>

        <div class="flag">
          <span></span>
          <span>{{ $t('report.m3') }}</span>
        </div>

        <div class="post">
          <ul class="gray myul">
            <li>{{ $t('report.t10') }}：</li>
            <li class="gray-item-background-1">{{ $t('report.s1') }}：</li>
            <li class="gray-item-background-2">{{ $t('report.s2') }}：</li>
            <li class="gray-item-background-3">{{ $t('report.s3') }}：</li>
            <li class="gray-item-background-4">{{ $t('report.s4') }}：</li>
            <li class="gray-item-background-5">{{ $t('report.s5') }}：</li>
            <li class="gray-item-background-6">{{ $t('report.s6') }}：</li>
          </ul>
          <ul class="myul">
            <li>{{ $t('report.t12') }}（mm）：</li>
            <li>{{ postData ? postData.startPost[0] : '' }}</li>
            <li>{{ postData ? postData.startPost[1] : '' }}</li>
            <li>{{ postData ? postData.startPost[2] : '' }}</li>
            <li>{{ postData ? postData.startPost[3] : '' }}</li>
            <li>{{ postData ? postData.startPost[4] : '' }}</li>
            <li>{{ postData ? postData.startPost[5] : '' }}</li>
          </ul>
          <ul class="myul">
            <li>{{ $t('report.t11') }}：</li>
            <li>{{ postData && postData.startUse && postData.startUse[0] ? postData.startUse[0].name
              + '（' + postData.startUse[0].mix_long + '-' + postData.startUse[0].max_long + '）' : $t('report.undefined')
            }}</li>
            <li>{{ postData && postData.startUse && postData.startUse[1] ? postData.startUse[1].name
              + '（' + postData.startUse[1].mix_long + '-' + postData.startUse[1].max_long + '）' : $t('report.undefined')
            }}</li>
            <li>{{ postData && postData.startUse && postData.startUse[2] ? postData.startUse[2].name
              + '（' + postData.startUse[2].mix_long + '-' + postData.startUse[2].max_long + '）' : $t('report.undefined')
            }}</li>
            <li>{{ postData && postData.startUse && postData.startUse[3] ? postData.startUse[3].name
              + '（' + postData.startUse[3].mix_long + '-' + postData.startUse[3].max_long + '）' : $t('report.undefined')
            }}</li>
            <li>{{ postData && postData.startUse && postData.startUse[4] ? postData.startUse[4].name
              + '（' + postData.startUse[4].mix_long + '-' + postData.startUse[4].max_long + '）' : $t('report.undefined')
            }}</li>
            <li>{{ postData && postData.startUse && postData.startUse[5] ? postData.startUse[5].name
              + '（' + postData.startUse[5].mix_long + '-' + postData.startUse[5].max_long + '）' : $t('report.undefined')
            }}</li>
          </ul>
        </div>

        <div class="img">
          <ul class="myul">
            <li>
              <div>{{ $t('report.t2') }}</div>
            </li>
            <li>
              <div>{{ $t('report.t3') }}</div>
            </li>
            <li>
              <div>{{ $t('report.t4') }}</div>
            </li>
          </ul>
          <iframe class="model-3d" :src="$baseUrl + '/dabo/before3d?id=' + this.id + '&flag=0'" frameborder="0"></iframe>
          <ul class="myul">
            <li>
              <el-button class="look" type="primary" @click="dialog1 = true">{{ $t('report.btn') }}</el-button>
            </li>
            <li>
              <el-button class="look" type="primary" @click="dialog2 = true">{{ $t('report.btn') }}</el-button>
            </li>
            <li>
              <el-button class="look" type="primary" @click="dialog3 = true">{{ $t('report.btn') }}</el-button>
            </li>
          </ul>

        </div>

        <div class="flag">
          <span></span>
          <span>{{ $t('report.m4') }}</span>
        </div>

        <div class="post">
          <ul class="gray myul">
            <li>{{ $t('report.t10') }}：</li>
            <li class="gray-item-background-1">{{ $t('report.s1') }}：</li>
            <li class="gray-item-background-2">{{ $t('report.s2') }}：</li>
            <li class="gray-item-background-3">{{ $t('report.s3') }}：</li>
            <li class="gray-item-background-4">{{ $t('report.s4') }}：</li>
            <li class="gray-item-background-5">{{ $t('report.s5') }}：</li>
            <li class="gray-item-background-6">{{ $t('report.s6') }}：</li>
          </ul>
          <ul class="myul">
            <li>{{ $t('report.t12') }}（mm）：</li>
            <li>{{ postData ? postData.endPost[0] : '' }}</li>
            <li>{{ postData ? postData.endPost[1] : '' }}</li>
            <li>{{ postData ? postData.endPost[2] : '' }}</li>
            <li>{{ postData ? postData.endPost[3] : '' }}</li>
            <li>{{ postData ? postData.endPost[4] : '' }}</li>
            <li>{{ postData ? postData.endPost[5] : '' }}</li>
          </ul>
          <ul class="myul">
            <li>{{ $t('report.t11') }}：</li>
            <li>{{ postData && postData.finalUse && postData.finalUse[0] ? postData.finalUse[0].name
              + '（' + postData.finalUse[0].mix_long + '-' + postData.finalUse[0].max_long + '）' : $t('report.undefined')
            }}</li>
            <li>{{ postData && postData.finalUse && postData.finalUse[1] ? postData.finalUse[1].name
              + '（' + postData.finalUse[1].mix_long + '-' + postData.finalUse[1].max_long + '）' : $t('report.undefined')
            }}</li>
            <li>{{ postData && postData.finalUse && postData.finalUse[2] ? postData.finalUse[2].name
              + '（' + postData.finalUse[2].mix_long + '-' + postData.finalUse[2].max_long + '）' : $t('report.undefined')
            }}</li>
            <li>{{ postData && postData.finalUse && postData.finalUse[3] ? postData.finalUse[3].name
              + '（' + postData.finalUse[3].mix_long + '-' + postData.finalUse[3].max_long + '）' : $t('report.undefined')
            }}</li>
            <li>{{ postData && postData.finalUse && postData.finalUse[4] ? postData.finalUse[4].name
              + '（' + postData.finalUse[4].mix_long + '-' + postData.finalUse[4].max_long + '）' : $t('report.undefined')
            }}</li>
            <li>{{ postData && postData.finalUse && postData.finalUse[5] ? postData.finalUse[5].name
              + '（' + postData.finalUse[5].mix_long + '-' + postData.finalUse[5].max_long + '）' : $t('report.undefined')
            }}</li>
          </ul>
        </div>

        <div class="img">
          <ul class="myul">
            <li>
              <div>{{ $t('report.t2') }}</div>
            </li>
            <li>
              <div>{{ $t('report.t3') }}</div>
            </li>
            <li>
              <div>{{ $t('report.t4') }}</div>
            </li>
          </ul>
          <iframe class="model-3d" :src="$baseUrl + '/dabo/before3d?id=' + this.id + '&flag=1'" frameborder="0"></iframe>

        </div>
        <div>
          <el-button class="look" type="primary" @click="dialog3DVisible = true">{{ $t('report.3d') }}</el-button>
        </div>
        <div class="flag">
          <span></span>
          <span>{{ $t('report.m3') }}</span>
        </div>

        <div class="post">
          <ul class="gray myul">
            <li>{{ $t('report.t10') }}：</li>
            <li class="gray-item-background-1">{{ $t('report.s1') }}：</li>
            <li class="gray-item-background-2">{{ $t('report.s2') }}：</li>
            <li class="gray-item-background-3">{{ $t('report.s3') }}：</li>
            <li class="gray-item-background-4">{{ $t('report.s4') }}：</li>
            <li class="gray-item-background-5">{{ $t('report.s5') }}：</li>
            <li class="gray-item-background-6">{{ $t('report.s6') }}：</li>
          </ul>
          <ul class="myul">
            <li>{{ $t('report.t12') }}（mm）：</li>
            <li>{{ postData ? postData.startPost[0] : '' }}</li>
            <li>{{ postData ? postData.startPost[1] : '' }}</li>
            <li>{{ postData ? postData.startPost[2] : '' }}</li>
            <li>{{ postData ? postData.startPost[3] : '' }}</li>
            <li>{{ postData ? postData.startPost[4] : '' }}</li>
            <li>{{ postData ? postData.startPost[5] : '' }}</li>
          </ul>
          <ul class="myul">
            <li>{{ $t('report.t11') }}：</li>
            <li>{{ postData && postData.startUse && postData.startUse[0] ? postData.startUse[0].name
              + '（' + postData.startUse[0].mix_long + '-' + postData.startUse[0].max_long + '）' : $t('report.undefined')
            }}</li>
            <li>{{ postData && postData.startUse && postData.startUse[1] ? postData.startUse[1].name
              + '（' + postData.startUse[1].mix_long + '-' + postData.startUse[1].max_long + '）' : $t('report.undefined')
            }}</li>
            <li>{{ postData && postData.startUse && postData.startUse[2] ? postData.startUse[2].name
              + '（' + postData.startUse[2].mix_long + '-' + postData.startUse[2].max_long + '）' : $t('report.undefined')
            }}</li>
            <li>{{ postData && postData.startUse && postData.startUse[3] ? postData.startUse[3].name
              + '（' + postData.startUse[3].mix_long + '-' + postData.startUse[3].max_long + '）' : $t('report.undefined')
            }}</li>
            <li>{{ postData && postData.startUse && postData.startUse[4] ? postData.startUse[4].name
              + '（' + postData.startUse[4].mix_long + '-' + postData.startUse[4].max_long + '）' : $t('report.undefined')
            }}</li>
            <li>{{ postData && postData.startUse && postData.startUse[5] ? postData.startUse[5].name
              + '（' + postData.startUse[5].mix_long + '-' + postData.startUse[5].max_long + '）' : $t('report.undefined')
            }}</li>
          </ul>
        </div>
        <div class="flag">
          <span></span>
          <span>{{ $t('report.m4') }}</span>
        </div>

        <div class="post">
          <ul class="gray myul">
            <li>{{ $t('report.t10') }}：</li>
            <li class="gray-item-background-1">{{ $t('report.s1') }}：</li>
            <li class="gray-item-background-2">{{ $t('report.s2') }}：</li>
            <li class="gray-item-background-3">{{ $t('report.s3') }}：</li>
            <li class="gray-item-background-4">{{ $t('report.s4') }}：</li>
            <li class="gray-item-background-5">{{ $t('report.s5') }}：</li>
            <li class="gray-item-background-6">{{ $t('report.s6') }}：</li>
          </ul>
          <ul class="myul">
            <li>{{ $t('report.t12') }}：</li>
            <li>{{ postData ? postData.endPost[0] : '' }}</li>
            <li>{{ postData ? postData.endPost[1] : '' }}</li>
            <li>{{ postData ? postData.endPost[2] : '' }}</li>
            <li>{{ postData ? postData.endPost[3] : '' }}</li>
            <li>{{ postData ? postData.endPost[4] : '' }}</li>
            <li>{{ postData ? postData.endPost[5] : '' }}</li>
          </ul>
          <ul class="myul">
            <li>{{ $t('report.t11') }}：</li>
            <li>{{ postData && postData.finalUse && postData.finalUse[0] ? postData.finalUse[0].name
              + '（' + postData.finalUse[0].mix_long + '-' + postData.finalUse[0].max_long + '）' : $t('report.undefined')
            }}</li>
            <li>{{ postData && postData.finalUse && postData.finalUse[1] ? postData.finalUse[1].name
              + '（' + postData.finalUse[1].mix_long + '-' + postData.finalUse[1].max_long + '）' : $t('report.undefined')
            }}</li>
            <li>{{ postData && postData.finalUse && postData.finalUse[2] ? postData.finalUse[2].name
              + '（' + postData.finalUse[2].mix_long + '-' + postData.finalUse[2].max_long + '）' : $t('report.undefined')
            }}</li>
            <li>{{ postData && postData.finalUse && postData.finalUse[3] ? postData.finalUse[3].name
              + '（' + postData.finalUse[3].mix_long + '-' + postData.finalUse[3].max_long + '）' : $t('report.undefined')
            }}</li>
            <li>{{ postData && postData.finalUse && postData.finalUse[4] ? postData.finalUse[4].name
              + '（' + postData.finalUse[4].mix_long + '-' + postData.finalUse[4].max_long + '）' : $t('report.undefined')
            }}</li>
            <li>{{ postData && postData.finalUse && postData.finalUse[5] ? postData.finalUse[5].name
              + '（' + postData.finalUse[5].mix_long + '-' + postData.finalUse[5].max_long + '）' : $t('report.undefined')
            }}</li>
          </ul>
        </div>



        <div class="flag">
          <span></span>
          <span>{{ $t('report.m5') }}</span>
        </div>
        <div class="footer">
          <div>
            <ul class="gray myul">
              <li>{{ $t('report.t13') }}</li>
              <li>{{ $t('report.t14') }}</li>
            </ul>
            <ul class="myul" v-for="(item, index) in pratData" :key="index">
              <li>{{ item.name }}</li>
              <li>{{ item.number }}</li>
            </ul>
            <ul class="myul" v-for="(item, key, index) in postsList" :key="index">
              <li>{{ key }}</li>
              <li>{{ item }}</li>
            </ul>
          </div>
          <div>
            <div class="gray case">{{ $t('case.note') }}</div>
            <div class="note">
              {{ cases.note }}
            </div>
          </div>
        </div>


        <div class="btnBox">
          <div>
            <el-button class="btn1" type="primary" @click="edit">{{ $t('common.edit') }}</el-button>
          </div>
          <div>
            <el-button class="btn1 btn2" type="info" @click="$router.push('/case/list')">{{ $t('common.return')
            }}</el-button>
          </div>
        </div>
      </div>
      <el-dialog :title="$t('report.3d')" :visible.sync="dialog3DVisible" width="75%" top="5vh">
        <iframe
          :src="$baseUrl + '/dabo.php/dabo/case_library/look3d?id=' + this.id + '&before_after=0' + '&lang=' + $i18n.locale"
          style="width:100%;height:700px" frameborder="0" scrolling="no"></iframe>
      </el-dialog>
      <el-dialog :title="$t('report.t2')" :visible.sync="dialog1" width="75%" top="5vh">
        <iframe
          :src="$baseUrl + '/dabo.php/dabo/case_library/look3d?id=' + this.id + '&before_after=0&direction=0' + '&lang=' + $i18n.locale"
          style="width:100%;height:700px" frameborder="0" scrolling="no"></iframe>
      </el-dialog>
      <el-dialog :title="$t('report.t3')" :visible.sync="dialog2" width="75%" top="5vh">
        <iframe
          :src="$baseUrl + '/dabo.php/dabo/case_library/look3d?id=' + this.id + '&before_after=0&direction=1' + '&lang=' + $i18n.locale"
          style="width:100%;height:700px" frameborder="0" scrolling="no"></iframe>
      </el-dialog>
      <el-dialog :title="$t('report.t4')" :visible.sync="dialog3" width="75%" top="5vh">
        <iframe
          :src="$baseUrl + '/dabo.php/dabo/case_library/look3d?id=' + this.id + '&before_after=0&direction=2' + '&lang=' + $i18n.locale"
          style="width:100%;height:700px" frameborder="0" scrolling="no"></iframe>
      </el-dialog>

    </div>
  </NavMenu>
</template>
<style scoped>
.main {
  padding-bottom: 100px;
  width: 95%;
  margin: 0 auto;
  margin-top: 27px;
  padding: 25px 35px;
  background-color: #fff;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #F1F4F8;
  padding-bottom: 23px;
}

.header>div span {
  font-weight: 700;
  font-size: 20px;
  margin-left: 15px;
  vertical-align: middle;
}

.header img {
  width: 20px;
  height: 22px;
  vertical-align: middle;
}

.flag {
  display: flex;
  margin-top: 60px;
}

.flag span:first-child {
  height: 18px;
  width: 6px;
  background: #364284;
}

.flag span:last-child {
  font-size: 18px;
  color: #686868;
  margin-left: 15px;
}

.bseInfo {
  display: flex;
  margin-top: 37px;
  padding: 0 20px;
}

.bseInfo>div {
  margin-right: 67px;
}

.bseInfo>div span:first-child {
  font-size: 18px;
  color: #686868;
}

.bseInfo>div span:last-child {
  font-size: 18px;
  color: #000000;
  font-weight: 700;
  margin-left: 10px;
}

.gray {
  background-color: #F8F9FA;
  font-size: 18px;
  font-size: 700;
}

.kjParams,
.mParams,
.install,
.post {
  margin-top: 19px;
}

.myul {
  display: flex;
  width: 100%;
}

.model-3d {
  width: 100%;
  min-height: 300px;
}

.myul li {
  flex: 1;
  text-align: center;
  height: 54px;
  line-height: 54px;
  border: 1px solid #F1F4F8;
}

.imgbox {
  width: 90%;
}

.imgbox img {
  width: 100%;
}

.img {
  margin-top: 48px;
  color: #686868;
  font-size: 20px;
}

.img li {
  border: none !important;
  width: 30% !important;
  height: auto !important;
}

.look {
  background-color: #364284;
  color: #fff;
  margin-top: 20px;
}

.footer {
  display: flex;
  margin-top: 20px;
}

.footer>div {
  flex: 1;
}

.footer>div:first-child {
  margin-right: 30px;
}

.note {
  padding: 28px 38px;
}

.case {
  height: 54px;
  line-height: 54px;
  padding-left: 46px;
}

.btnBox {
  display: flex;
  justify-content: center;
}

.btnBox>div {
  margin-right: 33px;
}

.btnBox {
  margin-top: 62px;
}

.btn1,
.btn2,
.btn3 {
  width: 264px;
  height: 65px;
  font-size: 24px;
}

.btn1 {
  background-color: #364284;
  color: #fff;
}

.btn2 {
  background-color: #7ECDF6;
  color: #fff;
}

.btn3 {
  background-color: #F1F5F7;
  color: #1F2A66;
}

.gray-item-background-1 {
  background-color: #ff0000;
}

.gray-item-background-2 {
  background-color: #ff7e00;
}

.gray-item-background-3 {
  background-color: #dcd404;
}

.gray-item-background-4 {
  background-color: #0dd608;
}

.gray-item-background-5 {
  background-color: #7ecdf6;
}

.gray-item-background-6 {
  background-color: #626eaf;
}
</style>
<script>
export default {
  data () {
    return {
      id: "",
      before_after: 0,//0术前1术后
      baseinfo: [
        {}, {}, {}
      ],
      install: "",
      control: "",
      cases: "",
      patient: "",
      postData: "",
      pratData: [],
      postsList: '',
      dialog3DVisible: false,
      dialog1: false,
      dialog2: false,
      dialog3: false,
      dialog4: false,
      dialog5: false,
      dialog6: false,
    };
  },

  mounted () {
    this.id = this.$route.query.id;
    if (!this.id) {
      this.$message.error(this.$t('message.caseNotFound'));
      return setTimeout(res => {
        this.$router.go(-1)
      }, 500)
    }
    this.getReport()
  },
  methods: {
    edit () {
      this.$router.push('/pre/installation?id=' + this.id);
    },
    getReport () {
      this.$api.getData({ id: this.id, before_after: this.before_after, flag: 1 }).then(res => {
        if (!res.code) {
          this.$message.error(this.$t('message.planeFail'));
          return setTimeout(() => {
            this.$router.push('/pre/installation?id=' + this.id);
          }, 500)
        }
        if (res.data.base.length <= 0) {
          this.$message.error(this.$t('message.caseNotFound'));
          setTimeout(res => {
            this.$router.go(-1)
          }, 500)
        }
        this.baseinfo = res.data.base
        this.install = res.data.install;
        this.control = res.data.control;
        this.cases = res.data.case;
        this.patient = res.data.patient;
        this.postData = res.data.postData;
        this.postsList = res.data.postsList
        this.getPrat();
      })
        .catch(err => {
          // this.$message.error('系统繁忙');
        })
    },
    getPrat () {
      if (this.install) {
        let name = this.install[0].solid_long + 'mm' + this.install[0].solid_name;
        let number = 1;

        if (this.install[0].solid_name == this.install[1].solid_name && this.install[0].solid_long == this.install[1].solid_long) {
          number++;
          this.pratData.push({ name, number });
        } else {
          this.pratData.push({ name, number });
          name = this.install[1].solid_long + 'mm' + this.install[1].solid_name;
          number = 1;
          this.pratData.push({ name, number });
        }
        console.log(this.pratData)
      }
    },

  }
};
</script>

